import Box from "@mui/material/Box"
import Slider, { SliderThumb } from "@mui/material/Slider"

import calendar_icon from "../images/calculator/icon-calendar.png"

const CalculatorSlider = ({
  max_value,
  step_value,
  setChangedValue,
  inputChanged,
  sliderColor,
  marks,
}) => {
  return (
    <Box className="d-flex justify-content-center">
      <Slider
        defaultValue={0}
        aria-label="Default"
        valueLabelDisplay="auto"
        max={max_value}
        track="inverted"
        sx={{
          color: sliderColor,
          height: "7px",

          "& .MuiSlider-thumb": {
            width: 27,
            height: 27,
            // backgroundSize: "50% 50%",
            // backgroundRepeat: "no-repeat",
            backgroundColor: "#fff",
            "&:before": {
              boxShadow: "0 4px 8px rgba(0,0,0,0.4)",
            },
          },
          "& .MuiSlider-rail": {
            color: "#74dfe1",
          },
          "& .MuiSlider-valueLabel": {
            color: "#74dfe1",
          },
          "& .MuiSlider-markLabel": {
            color: "#ffffff",
            fontSize: "11px",

            "&.MuiSlider-markActive": {
              opacity: 1,
              backgroundColor: "#74dfe1",
            },
          },
        }}
        step={step_value}
        marks={marks}
        value={parseInt(inputChanged)}
        onChange={(e) => {
          setChangedValue(e.target.value)
        }}
      />
    </Box>
  )
}

export default CalculatorSlider

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
// import { getAnalytics } from "firebase/analytics"
import { getAuth } from "firebase/auth"
import { getFirestore } from "firebase/firestore"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyDxiFAYbqxHYEYpjcrMNKphbMVigm3MfY0",
  authDomain: "ffl-website-410406.firebaseapp.com",
  projectId: "ffl-website-410406",
  storageBucket: "ffl-website-410406.appspot.com",
  messagingSenderId: "1087753627386",
  appId: "1:1087753627386:web:afe59f348edd8f27758b43",
  measurementId: "G-HXMNJVWVYT",
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)
export const db = getFirestore(app)
export default app
// const analytics = getAnalytics(app)

import ApplicationForm from "../compoients/ApplicationForm"
import css from "./css/apply.module.css"
// import bg from "../images/applyForm/bg.png"
import img1 from "../images/applyForm/fillin.png"
import { useEffect } from "react"
import { useMediaQuery } from "react-responsive"

const Apply = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" })

  useEffect(() => {
    //is mobile scorll to middle of the page
    if (isMobile) window.scrollTo({ top: 150, behavior: "smooth" })
    else window.scrollTo({ top: 0, behavior: "instant" })
  }, [])

  return (
    <div className={`${css.container} marginFixTop`}>
      <div className={css.bg_container}>
        <div className={css.title}>
          <h1>敢想敢做新貸度</h1>
        </div>
      </div>
      <div className={css.form_container}>
        <ApplicationForm />
      </div>
    </div>
  )
}

export default Apply

import React, { useEffect, useRef, useState } from "react"
import Icon1 from "./../images/featureSection/icon-1.png"
import Icon2 from "./../images/featureSection/icon-2.png"
import Icon3 from "./../images/featureSection/icon-3.png"
import PersonImage from "./../images/featureSection/people-1.png"

import "./css/featureSection.css"

import IconWithText from "../compoients/IconWithText"
import { useMediaQuery } from "react-responsive"
import { useNavigate } from "react-router-dom"
import { useData } from "../DataContext"
import { searchDescByLabel } from "../ults/searchDescByLabel"
import { wrapText } from "../ults/wrapText"

const FeatureSection = () => {
  const isDesktop = useMediaQuery({ query: "(min-width: 961px)" })
  const isMobile = useMediaQuery({ query: "(max-width: 500px)" })
  const isMobile_toogle = useMediaQuery({ query: "(max-width: 996px)" })
 
  const data = useData()?.content?.homePage
 
  const section2HeadTitle1 = searchDescByLabel(data, 'section2_head_title1');
  const section2HeadTitle2 = searchDescByLabel(data, 'section2_head_title2');
  const section2Title1 = searchDescByLabel(data, 'section2_title1');
  const section2Desc1 = searchDescByLabel(data, 'section2_desc1');
  const section2Title2 = searchDescByLabel(data, 'section2_title2');
  const section2Desc2 = searchDescByLabel(data, 'section2_desc2');
  const section2Title3 = searchDescByLabel(data, 'section2_title3');
  const section2Desc3 = searchDescByLabel(data, 'section2_desc3');

  const featureList = [
    {
      refNum: "1",
      icon: Icon1,
      title: section2Title1,
      desc: wrapText(section2Desc1),
    },
    {
      refNum: "2",
      icon: Icon2,
      title: section2Title2,
      desc: wrapText(section2Desc2),
    },
    {
      refNum: "3",
      icon: Icon3,
      title: section2Title3,
      desc: wrapText(section2Desc3),
    },
  ]
  const navigate = useNavigate()

  const getRef = (refNum) => {
    switch (refNum) {
      case "1":
        return icon_text_ref1
      case "2":
        return icon_text_ref2
      case "3":
        return icon_text_ref3
      default:
        return null
    }
  }
  const [pageYOffset, setPageYOffset] = useState(0)
  useEffect(() => {
    function handleScroll() {
      setPageYOffset(window.scrollY)
    }
    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const head_ref = useRef(null)
  const icon_text_ref1 = useRef(null)
  const icon_text_ref2 = useRef(null)
  const icon_text_ref3 = useRef(null)
  const btn_ref = useRef(null)

  useEffect(() => {
    // if (isDesktop) {
    //   if (pageYOffset >= 700) {
    //     home_section2_ref.current.classList.add("fade-up-element")
    //   }
    // }
    if (isDesktop || isMobile || isMobile_toogle) {
      if (pageYOffset >= 200) {
        head_ref.current.classList.add("fade-up-element-slow")
      }
      if (pageYOffset >= 350) {
        icon_text_ref1.current.classList.add("fade-up-element-slow")
      }
      if (pageYOffset >= 470) {
        icon_text_ref2.current.classList.add("fade-up-element-slow")
      }
      if (pageYOffset >= 600) {
        icon_text_ref3.current.classList.add("fade-up-element-slow")
      }
      if (pageYOffset >= 750) {
        btn_ref.current.classList.add("fade-up-element-slow")
      }
    }
  }, [pageYOffset])

  return (
    <div className="feature-section">
      <div className="feature-text color2">
        <div className="mb-4 opactiy0" ref={head_ref}>
          <h1>{section2HeadTitle1}</h1>
          <h1>{section2HeadTitle2}</h1>
        </div>

        <div className="feature-items">
          {featureList?.map((item, idx) => (
            <div key={idx} ref={getRef(item.refNum)} className="opactiy0">
              <IconWithText
                icon={item.icon}
                title={item.title}
                desc={item.desc}
              />
            </div>
          ))}
        </div>
        <button
          className="feature-button button_hover_motion opactiy0"
          ref={btn_ref}
          onClick={() => {
            navigate("/apply")
          }}
        >
          了解FFL免TU貸款
        </button>
      </div>
      <div className="feature-image">
        <img src={PersonImage} alt="Person skiing" />
      </div>
    </div>
  )
}

export default FeatureSection

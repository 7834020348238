import css from "./css/aboutus.module.css"
import bg from "../images/applyForm/bg.png"

import { useEffect } from "react"
import FeatureGrid_AboutUs from "../compoients/FeatureGrid_AboutUs"
import ApplySteps from "../section/home/ApplySteps"

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" })
  }, [])
  return (
    <div className={`${css.container} marginFixTop`}>
      <div className={css.bg_container}>
        <div className={css.title}>
          <h1>關於FFL</h1>
        </div>
      </div>
      <div className={css.form_container}>
        <div className={css.content}>
          <h2 className="mt-5 mb-5">聯絡FFL</h2>
        </div>
        <FeatureGrid_AboutUs />
        <div style={{ marginTop: "100px", marginBottom: "50px" }}>
          <ApplySteps />
        </div>
      </div>
    </div>
  )
}

export default AboutUs

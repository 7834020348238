import { Fragment } from "react"
import "./css/featureGrid.css"
import { Grid, Paper, Typography, Button, Box } from "@mui/material"
import ImageIcon from "@mui/icons-material/Image" // Placeholder for the icon, replace with your icons
import icon1 from "../images/privateloan/icon-1.png"
import icon2 from "../images/privateloan/icon-2.png"
import icon3 from "../images/privateloan/icon-3.png"
import icon4 from "../images/privateloan/icon-4.png"
import icon5 from "../images/privateloan/icon-5.png"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import css from "./css/cardSlider.module.css"
import { useMediaQuery } from "react-responsive"
import { useData } from "../DataContext"
import { searchDescByLabel } from "../ults/searchDescByLabel"
import { wrapText } from "../ults/wrapText"

const FeatureCard = ({ icon, title, description }) => {
  return (
    <Paper
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
      elevation={2}
      className="feature-card-paper"
    >
      <Box sx={{ mb: 2, height: "100px" }}>
        <img src={icon} alt="icon" className="feature-card-img" />
      </Box>
      <div style={{ height: "100px" }}>
        <Typography
          variant="h5"
          component="h5"
          sx={{ mb: 1, fontWeight: "500" }}
        >
          {title}
        </Typography>
        <Typography variant="body1">{description}</Typography>
      </div>
    </Paper>
  )
}

const ActionCard = ({ title, smallText, buttonText }) => {
  return (
    <Paper
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "300px",
        border: "none",
        boxShadow: "none",
      }}
      elevation={2}
    >
      <Typography variant="h6" component="h3" sx={{ mb: 2 }}>
        {title}
      </Typography>
      <Typography variant="body2" sx={{ mb: 3 }}>
        {smallText}
      </Typography>

      <button
        className="feature-action-card-btn button_hover_motion2"
        onClick={() => (window.location.href = "/apply")}
      >
        {buttonText}
      </button>
    </Paper>
  )
}

export default function FeatureGrid() {
  const isBreakpoint = useMediaQuery({ minWidth: 300, maxWidth: 768 })
  const settings = {
    className: `${css.cardSlider_container}`,
    centerPadding: "20px",
    centerMode: true,
    swipeToSlide: true,
    autoplay: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  const data = useData()?.content?.personalLoan

  const type1_title = searchDescByLabel(data, "type1_title")
  const type1_desc = wrapText(searchDescByLabel(data, "type1_desc"))
  const type2_title = searchDescByLabel(data, "type2_title")
  const type2_desc = wrapText(searchDescByLabel(data, "type2_desc"))
  const type3_title = searchDescByLabel(data, "type3_title")
  const type3_desc = wrapText(searchDescByLabel(data, "type3_desc"))
  const type4_title = searchDescByLabel(data, "type4_title")
  const type4_desc = wrapText(searchDescByLabel(data, "type4_desc"))
  const type5_title = searchDescByLabel(data, "type5_title")
  const type5_desc = wrapText(searchDescByLabel(data, "type5_desc"))
  const type_detail = wrapText(searchDescByLabel(data, "type_detail"))
  const type_detail_desc = wrapText(searchDescByLabel(data, "type_detail_desc"))

  const List1 = [
    {
      icon: icon1,
      title: type1_title,
      desc: type1_desc,
    },
    {
      icon: icon2,
      title: type2_title,
      desc: type2_desc,
    },
    {
      title: type_detail,
      smallText: type_detail_desc,
    },
    {
      icon: icon3,
      title: type3_title,
      desc: type3_desc,
    },
    {
      icon: icon4,
      title: type4_title,
      desc: type4_desc,
    },
    {
      icon: icon5,
      title: type5_title,
      desc: type5_desc,
    },
  ]

  return (
    <>
      {!isBreakpoint ? (
        <>
          <Grid container spacing={4} className="feature-grid">
            <Grid item xs={12} sm={6} md={4} className="feature-grid-item">
              <FeatureCard
                icon={icon1}
                title={type1_title}
                description={type1_desc}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="feature-grid-item">
              <FeatureCard
                icon={icon2}
                title={type2_title}
                description={type2_desc}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="feature-grid-item">
              <ActionCard
                title={type_detail}
                smallText={type_detail_desc}
                buttonText="了解更多"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} className="feature-grid-item">
              <FeatureCard
                icon={icon3}
                title={type3_title}
                description={type3_desc}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="feature-grid-item">
              <FeatureCard
                icon={icon4}
                title={type4_title}
                description={type4_desc}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="feature-grid-item">
              <FeatureCard
                icon={icon5}
                title={type5_title}
                description={type5_desc}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <div className="featureGrid-papers-container">
          <Slider {...settings}>
            {List1.map((item, idx) => {
              if (item.icon) {
                return (
                  <div className={css.item}>
                    <FeatureCard
                      icon={item.icon}
                      title={item.title}
                      description={item.desc}
                    />
                  </div>
                )
              } else {
                return (
                  <ActionCard
                    title={item.title}
                    smallText={item.desc}
                    buttonText="了解更多"
                  />
                )
              }
            })}
            <div className={css.item}>
              <FeatureCard
                icon={icon1}
                title={type1_title}
                description={type1_desc}
              />
            </div>
            <div className={css.item}>
              <FeatureCard
                icon={icon2}
                title={type2_title}
                description={type2_desc}
              />
            </div>
            <div className={css.item}>
              <ActionCard
                title={type_detail}
                smallText={type_detail_desc}
                buttonText="了解更多"
              />
            </div>
            <div className={css.item}>
              <FeatureCard
                icon={icon3}
                title={type3_title}
                description={type3_desc}
              />
            </div>
            <div className={css.item}>
              <FeatureCard
                icon={icon4}
                title={type4_title}
                description={type4_desc}
              />
            </div>
            <div className={css.item}>
              <FeatureCard
                icon={icon5}
                title={type5_title}
                description={type5_desc}
              />
            </div>
          </Slider>
        </div>
      )}
    </>
  )
}

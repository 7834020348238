import css from "./css/calculator.module.css"

import { useState, useEffect } from "react"
import CalculatorSlider from "./CalculatorSlider"
import cash_icon from "../images/calculator/icon-cash.png"
import calendar_icon from "../images/calculator/icon-calendar.png"
import doll_icon from "../images/calculator/icon-doll.png"

import { ApplyBtn } from "./Btn"
import { useNavigate } from "react-router-dom"

const Calculator = () => {
  const [displayAmount, setDisplayAmount] = useState(0)
  const [principal, setPrincipal] = useState(50000)
  const [period, setPeriod] = useState(12)
  const [monthlyPay, setMonthlyPay] = useState(0)

  const navigate = useNavigate()

  const HandleAmount = (value) => {
    setPrincipal(value)
    // FormatMoney(value)
  }

  // const FormatMoney = (value) => {
  //   setDisplayAmount(value.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"))
  // }

  const marks = [
    {
      value: 0,
      label: "最小金額",
    },
    {
      value: 500000,
      label: "最大金額",
    },
  ]

  useEffect(() => {
    let P = principal
    let t = period
    let i = 0.3 / 12 //30 厘
    let result = 0
    if (t !== 0) {
      result = P * ((i * (1 + i) ** t) / ((1 + i) ** t - 1))

      if (P === 0 || t === 0 || P === "" || t === "") {
        setMonthlyPay("")
      } else {
        setMonthlyPay(Math.round(result))
      }
    }
  }, [principal, period, monthlyPay])

  return (
    <div className={css.container}>
      <div className={css.box_container}>
        <div className={css.row_container}>
          <div className={css.item_title}>
            <img src={doll_icon} alt="money" />
            <span>理想貸款金額</span>
          </div>
          <div className={`${css.calculator_input} ${css.money_input} `}>
            <span>&#36;</span>
            <input
              type="text"
              value={principal === 0 ? "" : principal}
              onChange={(e) => {
                setPrincipal(e.target.value)
              }}
            />
          </div>
        </div>

        <CalculatorSlider
          max_value={500000}
          step_value={1000}
          setChangedValue={HandleAmount}
          inputChanged={principal}
          sliderColor={"#74dfe1"}
          marks={marks}
        />

        <div className={`${css.row_container} mt-4`}>
          <div className={css.item_title}>
            <img src={calendar_icon} alt="monthlybill" />
            <span>供款期</span>
          </div>
          <div className={`${css.calculator_input} ${css.month_input}`}>
            <span className="ms-2">Months</span>
            <input
              type="text"
              value={period === 0 ? "" : period}
              onChange={(e) => {
                setPeriod(e.target.value)
              }}
            />
          </div>
        </div>
        <CalculatorSlider
          max_value={120}
          step_value={12}
          setChangedValue={setPeriod}
          inputChanged={period}
          sliderColor={"#74dfe1"}
          marks={false}
        />
        <div className={`${css.row_container} mt-4`}>
          <div className={css.result}>
            <img src={cash_icon} alt="monthlybill" />
            <span>每期還款額</span>
          </div>
          <span className="color5" style={{ fontSize: "1.5rem" }}>
            $ {monthlyPay}
          </span>
        </div>
        <div className="d-flex justify-content-center">
          <button
            className="applyNowBtn button_hover_motion2 "
            onClick={() => {
              navigate("/apply")
            }}
          >
            立即申請
          </button>
        </div>
      </div>
    </div>
  )
}

export default Calculator

import styles from "./applyStep.module.css"
import styleSteps from "./applySteps.module.css"
import arrowImage from "../../images/applyStep/icon-arrow.png"
import step1img from "../../images/applyStep/step-1.png"
import step2img from "../../images/applyStep/step-2.png"
import step3img from "../../images/applyStep/step-3.png"
import { useState, useEffect } from "react"
import { useData } from "../../DataContext"
import { searchDescByLabel } from "../../ults/searchDescByLabel"
import { wrapText } from "../../ults/wrapText"

const ApplySteps = () => {
  const selectedFontColor = "#ffffff"
  const unselectFontColor = "#989898"
  const selectedStepColor = "#3fdecc"
  const unselectStepColor = "#ffffff"
  const [selectedStep, setSelectedStep] = useState(1)
  const [rightSectionImg, setRightSectionImg] = useState(step1img)

  const ImgList = [step1img, step2img, step3img]

  const content = useData()?.content?.applyStep

  const step1_title = searchDescByLabel(content, "step1_title")
  const step1_subtitle = searchDescByLabel(content, "step1_subtitle")
  const step1_desc = wrapText(searchDescByLabel(content, "step1_desc"))
  const step2_title = searchDescByLabel(content, "step2_title")
  const step2_subtitle = searchDescByLabel(content, "step2_subtitle")
  const step2_desc = wrapText(searchDescByLabel(content, "step2_desc"))
  const step3_title = searchDescByLabel(content, "step3_title")
  const step3_subtitle = searchDescByLabel(content, "step3_subtitle")
  const step3_desc = wrapText(searchDescByLabel(content, "step3_desc"))

  const data = [
    {
      stepidx: 1,
      stepNumber: "01",
      stepTitle: step1_title,
      rightSideTitle: step1_subtitle,
      rightSideContent: step1_desc,
    },
    {
      stepidx: 2,
      stepNumber: "02",
      stepTitle: step2_title,
      rightSideTitle: step2_subtitle,
      rightSideContent: step2_desc,
    },
    {
      stepidx: 3,
      stepNumber: "03",
      stepTitle: step3_title,
      rightSideTitle: step3_subtitle,
      rightSideContent: step3_desc,
    },
  ]

  const [rightSideTitle, setRightSideTitle] = useState("")
  const [rightSideContent, setRightSectionContent] = useState("")

  useEffect(() => {
    if (data.length > 0) {
      if (
        rightSideTitle === "" &&
        rightSideContent === "" &&
        step1_subtitle &&
        step1_desc
      ) {
        setRightSideTitle(data[0].rightSideTitle)
        setRightSectionContent(data[0].rightSideContent)
        setRightSectionImg(ImgList[0])
      }
    }
  }, [data])
  return (
    <div className={styleSteps.container}>
      <h2>申請步驟</h2>
      <p>點樣申請FFL私人貸款呢？</p>
      <br />
      <div className={styleSteps.titleSection}>
        <div className={styleSteps.leftSection}>
          {data?.map((item, idx) => (
            <div
              key={idx}
              className={styles.step}
              style={{
                backgroundColor:
                  selectedStep === item.stepidx
                    ? selectedStepColor
                    : unselectStepColor,
                color:
                  selectedStep === item.stepidx
                    ? selectedFontColor
                    : unselectFontColor,
                boxShadow:
                  selectedStep === item.stepidx
                    ? "#3fdecc 0px 0px 11px 2px"
                    : "unset",
              }}
              onClick={() => {
                setSelectedStep(item.stepidx)
                setRightSectionImg(ImgList[item.stepidx - 1])
                setRightSideTitle(item.rightSideTitle)
                setRightSectionContent(item.rightSideContent)
              }}
            >
              <div className={styles.itemLeft}>
                <h2 className={styles.stepNumber}>{item.stepNumber}</h2>
                <h4 className={styles.stepTitle}>{item.stepTitle}</h4>
              </div>
              <div className={styles.arrowWrapper}>
                <img
                  src={arrowImage}
                  alt="Arrow"
                  className={styles.stepArrow}
                />
              </div>
            </div>
          ))}
        </div>
        <div className={styleSteps.rightSection}>
          <h2>{rightSideTitle}</h2>
          <div className={styleSteps.threeBoxs}>
            <div className={styleSteps.box1}></div>
            <div className={styleSteps.box2}></div>
            <div className={styleSteps.box3}></div>
          </div>
          <div className={styleSteps.rightSection_item}>
            <h4>{rightSideContent}</h4>
            <img
              src={rightSectionImg}
              alt="step1_img"
              style={{ width: selectedStep === 1 ? "300px" : "400px" }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ApplySteps

import { Fragment, useState, useMemo } from "react"
import "./css/featureGrid.css"
import { Grid, Paper, Typography, Button, Box } from "@mui/material"
import Pagination from "@mui/material/Pagination"

import backgroundImage from "../images/applyForm/banner.jpg"
import useFetch from "../hooks/useFetch"
import { ENV } from "../config"
import { useEffect } from "react"
import { Link } from "react-router-dom"

const FeatureCard = ({ thumbnail, title, description }) => {
  const titleParts = title.split("/n").map((part, index) => (
    <Fragment key={index}>
      {part}
      {index < title.split("/n").length - 1 && <br />}
    </Fragment>
  ))
  const descriptionParts = description.split("/n").map((part, index) => (
    <Fragment key={index}>
      {part}
      {index < description.split("/n").length - 1 && <br />}
    </Fragment>
  ))

  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
      }}
      elevation={2}
      className="feature-card-paper"
    >
      <div
        className="feature-blog-card-thumb"
        style={{
          backgroundImage: `url("${thumbnail}")`,
        }}
      />

      <div style={{ height: "100px", width: "300px", padding: "1rem" }}>
        <h5>{titleParts}</h5>
        <p>{descriptionParts}</p>
      </div>
      <div style={{ padding: "1rem" }}>
        <Link to={`/blog/${title}`}>
          <div className="blog-more-btn btnhover">more+</div>
        </Link>
      </div>
    </Paper>
  )
}

export default function FeatureGrid_Blog() {
  const { data } = useFetch(ENV + "/api/v1/post")
  const [postData, setPostData] = useState(null)

  //setup pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [postsPerPage] = useState(6) // Number of posts per page

  useEffect(() => {
    if (data) {
      setPostData(data)
      console.log("hello", data)
    }
  }, [data])

  // Change page
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage)
    window.scrollTo({ top: 300, behavior: "instant" })
  }

  // Calculate the current posts to display
  const filteredPosts = useMemo(() => {
    return postData
  }, [postData])
  const indexOfLastPost = currentPage * postsPerPage
  const indexOfFirstPost = indexOfLastPost - postsPerPage
  const currentPosts = filteredPosts?.slice(indexOfFirstPost, indexOfLastPost)

  return (
    <div className="featureGrid_Blog mt-5">
      <Grid container spacing={4} className="feature-grid">
        {currentPosts?.map((item, idx) => (
          <Grid
            key={idx}
            item
            xs={12}
            sm={6}
            md={4}
            className="feature-grid-item"
          >
            <FeatureCard
              thumbnail={item?.thumbnail}
              title={item?.title}
              description={item?.timestamp}
            />
          </Grid>
        ))}
      </Grid>
      {currentPosts?.length > 0 ? (
        <Pagination
          className="mt-5"
          count={Math.ceil(filteredPosts?.length / postsPerPage)}
          page={currentPage}
          onChange={handleChangePage}
          // color="primary"
          shape="rounded"
          sx={{ display: "flex", justifyContent: "center" }} // Center the pagination
        />
      ) : null}
    </div>
  )
}

// import ReactChatbot from "./chatbot/ReactChatbot"
import "./css/floatBtnlist.css"
import FbBtn from "./btns/FbBtn"
import PhoneBtn from "./btns/PhoneBtn"
import WhatsAppBtn from "./btns/WhatsAppBtn"

const FloatBtnList = ({ data }) => {
  // const phone = data?.phone.find((item) => item.label === "phone")?.description
  // console.log(phone)
  const phone = 25388300
  const whatsappNum = 92840210
  const fbLink = "https://www.facebook.com/profile.php?id=100076074545255"
  return (
    <div className="floatBtnList">
      <div className="floatBtnList-item">
        <PhoneBtn phone={phone} />
      </div>
      <div className="floatBtnList-item">
        <WhatsAppBtn phone={whatsappNum} />
      </div>
      <div className="floatBtnList-item">
        <FbBtn src={fbLink} />
      </div>
      {/* <div className="floatBtnList-item">
        <ReactChatbot />
      </div> */}
    </div>
  )
}

export default FloatBtnList

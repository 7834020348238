// import { Fragment } from "react"
// import "./css/featureGrid.css"
// import { Grid, Paper, Typography, Button, Box } from "@mui/material"
// import ImageIcon from "@mui/icons-material/Image" // Placeholder for the icon, replace with your icons
// import icon1 from "../images/aboutus/icon-1.png"
// import icon2 from "../images/aboutus/icon-2.png"
// import icon3 from "../images/aboutus/icon-3.png"
// import icon4 from "../images/aboutus/icon-4.png"
// import icon5 from "../images/aboutus/icon-5.png"

// const FeatureCard = ({ icon, title, description }) => {
//   const titleParts = title.split("/n").map((part, index) => (
//     <Fragment key={index}>
//       {part}
//       {index < title.split("/n").length - 1 && <br />}
//     </Fragment>
//   ))
//   const descriptionParts = description.split("/n").map((part, index) => (
//     <Fragment key={index}>
//       {part}
//       {index < description.split("/n").length - 1 && <br />}
//     </Fragment>
//   ))

//   return (
//     <Paper
//       sx={{
//         p: 2,
//         display: "flex",
//         flexDirection: "column",
//         alignItems: "center",
//       }}
//       elevation={2}
//       className="feature-card-paper"
//     >
//       <Box sx={{ mb: 2, height: "50px" }}>
//         <img src={icon} alt="icon" className="feature-card-img" />
//       </Box>
//       <div style={{ height: "100px" }}>
//         <Typography
//           variant="h5"
//           component="h5"
//           sx={{ mb: 1, fontWeight: "300" }}
//         >
//           {titleParts}
//         </Typography>
//         <Typography variant="span" component="span" sx={{ fontWeight: "300" }}>
//           {descriptionParts}
//         </Typography>
//       </div>
//     </Paper>
//   )
// }

// export default function FeatureGrid_AboutUs() {
//   return (
//     <div className="featureGrid_aboutUs">
//       <Grid container spacing={4} className="feature-grid">
//         <Grid item xs={12} sm={6} md={4} className="feature-grid-item">
//           <FeatureCard
//             icon={icon1}
//             title="地址"
//             description="8/F., Far East Consortium Building,204-206 Nathan Road, Kowloon"
//           />
//         </Grid>
//         <Grid item xs={12} sm={6} md={4} className="feature-grid-item">
//           <FeatureCard
//             icon={icon2}
//             title="電話"
//             description="TEL : 2538 8300/nFAX : 2538 8002"
//           />
//         </Grid>
//         <Grid item xs={12} sm={6} md={4} className="feature-grid-item">
//           <FeatureCard icon={icon3} title="EMAIL" description="CS@FFLL.HK" />
//         </Grid>
//       </Grid>
//       <Grid
//         container
//         spacing={4}
//         className="feature-grid mt-5"
//         maxWidth={"673px"}
//       >
//         <Grid item xs={12} sm={6} md={6} className="feature-grid-item">
//           <FeatureCard
//             icon={icon4}
//             title="地址"
//             description="8/F., Far East Consortium Building,204-206 Nathan Road, Kowloon"
//           />
//         </Grid>
//         <Grid item xs={12} sm={6} md={6} className="feature-grid-item">
//           <FeatureCard
//             icon={icon5}
//             title="WhatsApp"
//             description="TEL : 92840210"
//           />
//         </Grid>
//       </Grid>
//     </div>
//   )
// }
import { Fragment } from "react"
import "./css/featureGrid.css"
import { Grid, Paper, Typography, Box } from "@mui/material"
import ImageIcon from "@mui/icons-material/Image" // Placeholder for the icon, replace with your icons
import icon1 from "../images/aboutus/icon-1.png"
import icon2 from "../images/aboutus/icon-2.png"
import icon3 from "../images/aboutus/icon-3.png"
import icon4 from "../images/aboutus/icon-4.png"
import icon5 from "../images/aboutus/icon-5.png"

const FeatureCard = ({ icon, title, description }) => {
  return (
    <Paper
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
      elevation={2}
      className="feature-card-paper"
    >
      <Box sx={{ mb: 2, height: "50px" }}>
        <img src={icon} alt="icon" className="feature-card-img" />
      </Box>
      <div style={{ height: "100px" }}>
        <Typography
          variant="h5"
          component="h5"
          sx={{ mb: 1, fontWeight: "300" }}
        >
          {title}
        </Typography>
        <Typography variant="span" component="span" sx={{ fontWeight: "300" }}>
          {title === "電話" ? (
            <>
              <a href={`tel:85225388300`}>TEL: 2538 8300</a>
              <br />
              <a href={`tel:85225388002`}>FAX: 2538 8002</a>
            </>
          ) : title === "WhatsApp" ? (
            <a href={`https://api.whatsapp.com/send/?phone=85292840210`}>
              WhatsApp: 9284 0210
            </a>
          ) : (
            description
          )}
        </Typography>
      </div>
    </Paper>
  )
}

export default function FeatureGrid_AboutUs() {
  return (
    <div className="featureGrid_aboutUs">
      <Grid container spacing={4} className="feature-grid">
        <Grid item xs={12} sm={6} md={4} className="feature-grid-item">
          <FeatureCard
            icon={icon1}
            title="地址"
            description="8/F., Far East Consortium Building,204-206 Nathan Road, Kowloon"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} className="feature-grid-item">
          <FeatureCard
            icon={icon2}
            title="電話"
            description="2538 8300/n 2538 8002"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} className="feature-grid-item">
          <FeatureCard icon={icon3} title="EMAIL" description="CS@FFLL.HK" />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={4}
        className="feature-grid mt-5"
        maxWidth={"673px"}
      >
        <Grid item xs={12} sm={6} md={6} className="feature-grid-item">
          <FeatureCard
            icon={icon4}
            title="地址"
            description="8/F., Far East Consortium Building,204-206 Nathan Road, Kowloon"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} className="feature-grid-item">
          <FeatureCard
            icon={icon5}
            title="WhatsApp"
            description="TEL: 9284 0210"
          />
        </Grid>
      </Grid>
    </div>
  )
}

import css from "./css/btn.module.css"
import "./css/btn.css"

export default function Btn() {
  return <div className={css.container}>查看更多</div>
}

function Center_ApplyBtn({ handle }) {
  return (
    <button className={`center-applyBtn`} onClick={handle}>
      立即申請
    </button>
  )
}
function GoBtn({ label = "立即申請", handle }) {
  return (
    <button className={`goBtn color-purple animatedBtn`} onClick={handle}>
      {label}
    </button>
  )
}

function ApplyBtn({ label = "立即申請", handle }) {
  return (
    <button className={`applyBtn color-purple animatedBtn`} onClick={handle}>
      {label}
    </button>
  )
}
function ApplyBtnBig({ color, classColor, handle }) {
  return (
    <button
      className={`applyBtn-big ${classColor} animatedBtn`}
      style={color ? { backgroundColor: color } : null}
      onClick={handle}
    >
      立即申請
    </button>
  )
}

function SubmitBtnBig({ color, disabled, handle }) {
  return (
    <button
      className={`fullBtn ${color} ${
        disabled ? "btn-disabled" : "animatedBtn"
      }`}
      disabled={disabled}
      onClick={!disabled ? handle : null}
    >
      立即遞交
    </button>
  )
}
function NextStepBtn({ color, handle, disabled }) {
  return (
    <button
      disabled={disabled}
      className={`fullBtn ${
        disabled ? "btn-disabled" : "animatedBtn"
      } ${color}`}
      onClick={!disabled ? handle : null}
    >
      下一步
    </button>
  )
}
function PrevStepBtn({ color, handle }) {
  return (
    <button className={`fullBtn ${color} animatedBtn`} onClick={handle}>
      上一步
    </button>
  )
}

function EstimateApplyBtn({ handle }) {
  return (
    <button className="applyBtn color-orange animatedBtn" onClick={handle}>
      遞交申請
    </button>
  )
}

export {
  Btn,
  Center_ApplyBtn,
  ApplyBtn,
  EstimateApplyBtn,
  GoBtn,
  ApplyBtnBig,
  SubmitBtnBig,
  NextStepBtn,
  PrevStepBtn,
}

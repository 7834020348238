import "./css/loanProduct.css"
import banner from "../images/collateralloan/banner.png"
import banner_tablet768 from "../images/collateralloan/banner_tablet768.png"
import banner_mobile425 from "../images/collateralloan/banner_mobile425.png"
import { useEffect } from "react"
import ImageHorizontalDesc from "../compoients/ImageHorizontalDesc"
import ImageDesc from "../compoients/ImageDesc"
import "./css/collateralLoan.css"
import img1 from "../images/collateralloan/point3-1.png"
import img2 from "../images/collateralloan/point3-2.png"
import img3 from "../images/collateralloan/point3-3.png"
import { ApplyBtnBig } from "../compoients/Btn"
import { useNavigate } from "react-router-dom"
import { useMediaQuery } from "react-responsive"

const CollateralLoan = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" })
  }, [])

  const navigate = useNavigate()
  const isMobile = useMediaQuery({ query: "(max-width: 500px)" })
  const isMobile_toogle = useMediaQuery({ query: "(max-width: 996px)" })
  const isDesktop = useMediaQuery({ query: "(min-width: 997px)" })

  const isMobile_small = useMediaQuery({ query: "(max-width: 600px)" })
  const isTablet = useMediaQuery({ query: "(max-width: 960px)" })

  const backgroundImage = isMobile_small
    ? banner_mobile425
    : isTablet
    ? banner_tablet768
    : banner

  return (
    <div className="collateralLoan-container marginFixTop">
      <div
        className="loanProduct-banner"
        style={{ backgroundImage: `url(${backgroundImage}` }}
      >
        <div className="loanProduct-banner-text">
          <h1>抵押品貸款</h1>
          <hr className="loanProduct-banner-divider" />
          <p>汽車, 手錶, 鑽石或名貴手袋</p>
          <p>只要係有價值嘅資產, 都可以抵押</p>
          <p>手續簡單, 報價快捷, 輕鬆取得現金</p>
        </div>
      </div>
      <div className="collateralLoan-content">
        {isDesktop && (
          <div className="collateralLoan-imageBlock">
            <ImageHorizontalDesc
              image={img1}
              width={150}
              title={"低息車按揭貸款"}
              desc={
                "我地有專業既二手汽車估價員去為客人車輛估值，只要\n仍有市場價值，都歡迎查詢抵押條件及手續，套取資金\n易如反掌，我地有自置車場，確保抵押品存放條件優良"
              }
            />
            <ImageHorizontalDesc
              image={img2}
              width={100}
              title={"名貴手錶/鑽石暫存貸款"}
              desc={
                "我地有手錶及GIA鑽石鑒定師，會為客人提供最高估值既\n鑒定，為你提供最高額度的貸款，輕鬆應付現金需要。"
              }
            />
            <ImageHorizontalDesc
              image={img3}
              width={100}
              title={"手袋寄存貸款"}
              desc={
                "各式各種名貴手袋皮包，均可抵押，抵押品會存放\n於獨立的恆溫無塵貯存箱，保值又放心。"
              }
            />
          </div>
        )}
        {(isMobile || isMobile_toogle) && (
          <div className="collateralLoan-imageBlock">
            <ImageDesc
              image={img1}
              title={"低息車按揭貸款"}
              desc={
                "我地有專業既二手汽車估價員去為客人車輛估值，只要仍有市場價值，都歡迎查詢抵押條件及手續，套取資金易如反掌，我地有自置車場，確保抵押品存放條件優良"
              }
            />
            <ImageDesc
              image={img2}
              title={"名貴手錶/鑽石暫存貸款"}
              desc={
                "我地有手錶及GIA鑽石鑒定師，會為客人提供最高估值既鑒定，為你提供最高額度的貸款，輕鬆應付現金需要。"
              }
            />
            <ImageDesc
              image={img3}
              title={"手袋寄存貸款"}
              desc={
                "各式各種名貴手袋皮包，均可抵押，抵押品會存放於獨立的恆溫無塵貯存箱，保值又放心。"
              }
            />
          </div>
        )}
      </div>
      <div className="d-flex justify-content-center mt-5 mb-5">
        <ApplyBtnBig color={"#632e8d"} handle={() => navigate("/apply")} />
      </div>
    </div>
  )
}

export default CollateralLoan

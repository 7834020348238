import Container from "react-bootstrap/Container"
import Nav from "react-bootstrap/Nav"
import Navbar from "react-bootstrap/Navbar"
import NavDropdown from "react-bootstrap/NavDropdown"
import Offcanvas from "react-bootstrap/Offcanvas"
import css from "./css/topMenu.module.css"
import logo from "../images/icon/logo_small.png"

import "../compoients/css/btn.css"
import { useEffect, useState, useContext } from "react"
import { AuthContext } from "../AuthContext"
import { mainMenu } from "../data/topMenu"
import { useLocation } from "react-router-dom"
import { useMediaQuery } from "react-responsive"

const TopMenu = ({ content }) => {
  const { state, dispatch } = useContext(AuthContext)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const location = useLocation()
  const isMobile_toogle = useMediaQuery({ query: "(max-width: 991px)" })

  const topMenu_style =
    location.pathname === "/"
      ? {
          position: "fixed",
          background: "linear-gradient(rgb(50 49 49), rgb(0 0 0 / 0%))",
          top: "0px",
          zIndex: "99",
        }
      : {
          position: "fixed",
          background: "#2f3f59",
          top: "0px",
          zIndex: "99",
        }
  // : {
  //     position: "relative",
  //     background: "#2f3f59",
  //   }

  useEffect(() => {
    if (state.loggedIn || localStorage.getItem("isLogin") === "true") {
      setIsLoggedIn(true)
    } else {
      setIsLoggedIn(false)
    }
  }, [state, localStorage.getItem("isLogin")])

  const expand = "lg"
  const menuList = (item, index) => {
    // if (item.label === "貸款產品") {
    //   return (
    //     <NavDropdown
    //       title={item.label}
    //       id={`offcanvasNavbarDropdown-expand-${expand}`}
    //       key={index}
    //     >
    //       {mainMenu?.loanProduct?.map((subitem, idx) => (
    //         <div key={idx}>
    //           {subitem.order !== 1 && <NavDropdown.Divider />}
    //           <NavDropdown.Item href={subitem.url}>
    //             {subitem.label}
    //           </NavDropdown.Item>
    //         </div>
    //       ))}
    //     </NavDropdown>
    //   )
    // } else {
    return (
      <Nav.Link key={index} href={item.url}>
        {item.label}
      </Nav.Link>
    )
    // }
  }

  return (
    <div
      className={`${css.container} ${css.fixedNavbar}`}
      style={topMenu_style}
    >
      <Navbar key={false} bg="" expand={expand}>
        <Container fluid="sm">
          <Navbar.Brand href="/">
            <img src={logo} alt="ffl" className={css.logo} />
          </Navbar.Brand>

          <div className={css.mobile_menu_container}>
            {isMobile_toogle && (
              <Nav.Link href="/apply" className="pe-4">
                <button className="menu-apply button_hover_motion2 floating-button">
                  <span style={{ fontSize: "15px" }}>立即申請</span>
                </button>
              </Nav.Link>
            )}
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
          </div>
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${expand}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
            placement="end"
          >
            <Offcanvas.Header closeButton>
              {/* <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                Offcanvas
              </Offcanvas.Title> */}
              <Navbar.Brand href="/">
                <img src={logo} alt="ffl" className={css.logo} />
              </Navbar.Brand>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav
                className={`justify-content-end align-items-center flex-grow-1 pe-3 ${css.text}`}
              >
                {mainMenu?.main?.map((item, index) => menuList(item, index))}

                {/* {isLoggedIn ? (
                  <Nav.Link
                    href={
                      state.dashboardURL || localStorage.getItem("dashboardURL")
                    }
                  >
                    <button className="dashboard_btn color2">
                      <span>用戶界面</span>
                    </button>
                  </Nav.Link>
                ) : (
                  <Nav.Link href="/login">
                    <button className="menu-login color2">
                      <span>登入</span>
                    </button>
                  </Nav.Link>
                )} */}

                <Nav.Link href="/apply">
                  <button className="menu-apply button_hover_motion2">
                    <span style={{ fontSize: "15px" }}>立即申請</span>
                  </button>
                </Nav.Link>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </div>
  )
}

export default TopMenu
